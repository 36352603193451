import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import { Link } from "gatsby"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import Image from "../components/tacticalImg"
import SEO from "../components/seo"
import CTA from "../components/cta"

const LeadText = styled.div`
  text-align: center;
  p {
    margin: 0 auto;
    max-width: 75ch;
  }
`

const CarouselContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
`

const BrownBox = styled.div`
  margin: 0;
  padding: var(--xl) 0;
  background: var(--subheading);
  .icon-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 640px) {
      flex-direction: column;
      span {
        padding-bottom: var(--medium);
      }
    }
  }
  svg {
    margin: 0 auto;
    display: block;
    margin-bottom: var(--medium);
  }
  span {
    font-family: "Prata", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: var(--white);
    font-size: 1.2rem;
    text-align: center;
    display: block;
    width: 15ch;
    line-height: 1.5rem;
  }
`

const IndexPage = () => {
  const salonImages = []
  for (let index = 0; index < 8; index++) {
    let object = {}
    object.alt = `Salon ${index}`
    object.src = `salon${index}.jpg`
    salonImages.push(object)
  }
  return (
    <Layout>
      <SEO title="Home" />
      <Image src="hero.jpg" alt="Elsa Massotherapie" />
      <LeadText className="container highlow">
        <h2>Des thérapies professionnelles, adaptées à vos besoins.</h2>
        <p>
          Bienvenue chez Elsa, espace de massothérapie et bien-être, où j’ai le
          plaisir de vous accueillir au cœur de Lausanne. Je vous garantis un
          cadre de qualité, de confiance et de calme. Les soins proposés sont
          des techniques de massothérapie reconnues et certifiées. Elles
          conviennent aussi bien à des attentes de bien-être et de relaxation,
          comme à des besoins thérapeutiques spécifiques.
        </p>
      </LeadText>
      <div className="container">
        <div
          className="grid"
          style={{
            boxShadow: "var(--box-shadow)",
            marginBottom: "var(--xl)",
            gridColumnGap: 0,
          }}
        >
          <div className="col-md-6 vertical-center">
            <Image src="home1.jpg" alt="Home 1" />
          </div>
          <div
            className="col-md-6 vertical-center"
            style={{ padding: "var(--large)" }}
          >
            <h3>Massothérapie</h3>
            <p>
              Les services de massothérapie, s’inspirent du massage suédois, une
              technique de massage thérapeutique qui vise une détente musculaire
              et une relaxation profonde.
            </p>
            <div style={{ marginTop: "var(--medium)" }}>
              <Link to="/soins-corporels/" className="btn soft-cta">
                En Savoir
              </Link>
            </div>
          </div>
        </div>
        <div
          className="grid"
          style={{
            gridColumnGap: 0,
            boxShadow: "var(--box-shadow)",
            marginBottom: "var(--xl2)",
          }}
        >
          <div
            className="col-md-6 vertical-center"
            style={{ padding: "var(--large)" }}
          >
            <h3>Bien-être</h3>
            <p>
              État agréable résultant de la satisfaction des besoins du corps et
              du calme de l'esprit:
              <ul className="services">
                <li>Éprouver une sensation de bien-être.</li>
                <li>Aisance matérielle qui permet une existence agréable.</li>
              </ul>
            </p>
            <div style={{ marginTop: "var(--medium)" }}>
              <Link to="/soins-visage/" className="btn soft-cta">
                En Savoir
              </Link>
            </div>
          </div>
          <div className="col-md-6 vertical-center mobile-first">
            <Image src="home2.jpg" alt="Home 2" />
          </div>
        </div>
      </div>
      <BrownBox>
        <div className="container">
          <div className="icon-box">
            <div>
              <svg
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M38.5134 13.3176C38.2571 13.3731 34.8172 14.177 30.4097 16.9651C29.7475 13.7432 27.7877 7.7105 21.9883 1.575L20.5 0L19.0117 1.57705C13.1918 7.73517 11.2401 13.7843 10.5842 17.0268C6.16025 14.1914 2.747 13.3772 2.4969 13.3196L0 12.7604V15.3264C0 30.3156 8.04625 40 20.5 40C32.761 40 41 30.0853 41 15.3264V12.7727L38.5134 13.3176ZM14.3479 19.5168C14.352 19.449 14.6595 13.1428 20.5 6.08615C26.2687 13.077 26.6479 19.4387 26.65 19.4387V19.6957C24.3511 21.6118 22.2805 23.7879 20.4795 26.1807C18.6734 23.8302 16.6174 21.684 14.3479 19.78V19.5168ZM20.5 35.8877C9.6022 35.8877 5.02455 27.2602 4.22915 18.279C7.6219 19.7553 13.6919 23.2672 18.7985 30.8687L20.5225 33.4368L22.2138 30.8461C27.1871 23.222 33.335 19.7204 36.7606 18.2585C35.9447 26.9518 31.3732 35.8877 20.5 35.8877Z"
                  fill="white"
                />
              </svg>
              <span>Thérapies Conventionnelles</span>
            </div>
            <div>
              <svg
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M38.5134 13.3176C38.2571 13.3731 34.8172 14.177 30.4097 16.9651C29.7475 13.7432 27.7877 7.7105 21.9883 1.575L20.5 0L19.0117 1.57705C13.1918 7.73517 11.2401 13.7843 10.5842 17.0268C6.16025 14.1914 2.747 13.3772 2.4969 13.3196L0 12.7604V15.3264C0 30.3156 8.04625 40 20.5 40C32.761 40 41 30.0853 41 15.3264V12.7727L38.5134 13.3176ZM14.3479 19.5168C14.352 19.449 14.6595 13.1428 20.5 6.08615C26.2687 13.077 26.6479 19.4387 26.65 19.4387V19.6957C24.3511 21.6118 22.2805 23.7879 20.4795 26.1807C18.6734 23.8302 16.6174 21.684 14.3479 19.78V19.5168ZM20.5 35.8877C9.6022 35.8877 5.02455 27.2602 4.22915 18.279C7.6219 19.7553 13.6919 23.2672 18.7985 30.8687L20.5225 33.4368L22.2138 30.8461C27.1871 23.222 33.335 19.7204 36.7606 18.2585C35.9447 26.9518 31.3732 35.8877 20.5 35.8877Z"
                  fill="white"
                />
              </svg>
              <span>Prévention et Traitement des Maladies</span>
            </div>
            <div>
              <svg
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M38.5134 13.3176C38.2571 13.3731 34.8172 14.177 30.4097 16.9651C29.7475 13.7432 27.7877 7.7105 21.9883 1.575L20.5 0L19.0117 1.57705C13.1918 7.73517 11.2401 13.7843 10.5842 17.0268C6.16025 14.1914 2.747 13.3772 2.4969 13.3196L0 12.7604V15.3264C0 30.3156 8.04625 40 20.5 40C32.761 40 41 30.0853 41 15.3264V12.7727L38.5134 13.3176ZM14.3479 19.5168C14.352 19.449 14.6595 13.1428 20.5 6.08615C26.2687 13.077 26.6479 19.4387 26.65 19.4387V19.6957C24.3511 21.6118 22.2805 23.7879 20.4795 26.1807C18.6734 23.8302 16.6174 21.684 14.3479 19.78V19.5168ZM20.5 35.8877C9.6022 35.8877 5.02455 27.2602 4.22915 18.279C7.6219 19.7553 13.6919 23.2672 18.7985 30.8687L20.5225 33.4368L22.2138 30.8461C27.1871 23.222 33.335 19.7204 36.7606 18.2585C35.9447 26.9518 31.3732 35.8877 20.5 35.8877Z"
                  fill="white"
                />
              </svg>
              <span>Thérapies Alternatives</span>
            </div>
          </div>
        </div>
      </BrownBox>
      <LeadText className="container highlow">
        <h2>Accueil</h2>
        <p style={{ fontWeight: 700 }}>
          La philosophie de l'espace de massothérapie?
        </p>
        <p>
          Nous sommes tous différents les uns des autres. Il est donc important
          pour moi de tenir compte de l'individualité de chaque personne pour
          adapter le traitement à ses besoins. En tant que massothérapeute,
          esthéticienne et cosmétologue, j’utilise des thérapies alternatives.
        </p>
        <p>
          L’exigence de qualité est un devoir. Le plaisir n’existe que s’il y a
          du respect et de la confiance. Chaque individu est unique et inspire à
          un équilibre qui lui est propre.
        </p>
        <p>
          Chaque séance est adaptée à vos besoins, que ce soit pour un mal de
          dos, une contracture musculaire, que vous viviez des périodes de
          stress ou tout simplement pour la relaxation, c’est un plaisir pour
          moi de vous offrir un service professionnel de qualité.
        </p>
        <p>
          Dans une atmosphère confidentielle, vous vivez une expérience
          exclusive d’éveil de vos sens, et de votre beauté…
        </p>
        <p>
          Après une journée de travail fatigant, vous méritez un bon massage
          relaxant et si vous le désirez une pause tisane vous est servie.
        </p>
        <p
          style={{
            paddingBottom: "var(--xl2)",
          }}
        >
          Mon approche est d'accueillir chaque personne dans sa globalité, en
          offrant un service personnalisé et unique dans un environnement
          convivial et chaleureux.
        </p>
        <CarouselContainer>
          <Carousel showStatus={false} showThumbs={false}>
            {salonImages.map((salon, index) => (
              <div key={index}>
                <Image src={salon.src} alt={salon.alt} />
              </div>
            ))}
          </Carousel>
        </CarouselContainer>
      </LeadText>
      <CTA />
    </Layout>
  )
}

export default IndexPage
